/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:129f5e3c-d8e0-4749-98e6-daa1496199fb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_z1coXeq6k",
    "aws_user_pools_web_client_id": "39560e0pljaisetq9amj1klr0j",
    "oauth": {
        "domain": "wateenhelfa91df84-fa91df84-master.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.wateenhel.nl/,https://www.wateenhel.nl/",
        "redirectSignOut": "http://localhost:3000/,https://dev.wateenhel.nl/,https://www.wateenhel.nl/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
