import "./App.css";

import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";

import { CognitoUser } from "amazon-cognito-identity-js";
import { Fire } from "./Fire";
import { withOAuth } from "aws-amplify-react";

const App: React.FC<any> = (props) => {
  const [error, setError] = useState<any>();
  const [user, setUser] = useState<CognitoUser | null>(null);

  const handleAuthEvents = (data: any) => {
    switch (data.payload.event) {
      case "signIn":
        setUser(data.payload.data);
        break;

      case "signIn_failure":
        setUser(null);
        setError(data.payload.data);
        break;

      default:
        break;
    }
  };

  const getUser = async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch {
      setUser(null);
    }
  };

  useEffect(() => {
    Hub.listen("auth", handleAuthEvents);
    getUser();

    return () => Hub.remove("auth", handleAuthEvents);
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (e) {
      setError(e);
    }
  };

  if (error) {
    console.error(error);
    return <>Something went wrong, please reload the page</>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-title">Wat een Hel</div>
        <div>
          {!user && (
            <button className="App-login" onClick={props.OAuthSignIn}>
              Sign in
            </button>
          )}
          {user && (
            <>
              Hello! {user.getUsername()}
              <button className="App-login" onClick={signOut}>
                Sign out
              </button>
              <button
                className="App-login"
                onClick={async () => {
                  Auth.updateUserAttributes(user, { nickname: "Bob" });
                  const attributes = await Auth.userAttributes(user);
                  console.log(attributes);
                }}
              >
                Set nickname to Bob
              </button>
            </>
          )}
        </div>
      </header>

      <Fire />
    </div>
  );
};

export default withOAuth(App);
