import Amplify from "aws-amplify";
import aws_exports from "../aws-exports";

export function configureAws() {
  if (process.env.NODE_ENV === "development") {
    aws_exports.oauth.redirectSignIn = "http://localhost:3000/";
    aws_exports.oauth.redirectSignOut = "http://localhost:3000/";
  } else if (process.env.REACT_APP_ENV === "test") {
    aws_exports.oauth.redirectSignIn = "https://dev.wateenhel.nl/";
    aws_exports.oauth.redirectSignOut = "https://dev.wateenhel.nl/";
  } else if (process.env.REACT_APP_ENV === "master") {
    aws_exports.oauth.redirectSignIn = "https://www.wateenhel.nl/";
    aws_exports.oauth.redirectSignOut = "https://www.wateenhel.nl/";
  }

  Amplify.configure(aws_exports);
}
